import React from "react";
import AppLayout from "../components/AppLayout";
import PagesHeader from "../components/PagesHeader";
import AllProjects from "../components/AllProjects";

const Projects = () => {
  const title = "Hi these are some of my";
  const emph = "Projects";
  const description =
    "I have been in the industry for last 3 years and together we can work on cool projects like these.";
  return (
    <AppLayout>
      <PagesHeader title={title} description={description} emph={emph} />
      <AllProjects />{" "}
    </AppLayout>
  );
};

export default Projects;
