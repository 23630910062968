import React from "react";
function iframe() {
  return {
    __html: '<iframe src="/monkey.html" frameborder="0" width="100%" height="800"></iframe>',
  };
}
const MonkeyGame = () => {
  return <div dangerouslySetInnerHTML={iframe()}></div>;
};

export default MonkeyGame;
