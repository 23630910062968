import React from "react";
import logoImage from "../assets/dp. (5).svg";
import { Link } from "react-router-dom";

const Navbar = ({ toggleModal, toggleContrast }) => {
  return (
    <div className="navigationbar">
      <nav>
        <figure className="nav__link">
          <Link to="/">
            <img
              id="personal-logo"
              src={logoImage}
              style={{ borderRadius: "50%" }}
              alt=""
            />
          </Link>
        </figure>
        <ul className="nav__link--list">
          <li className="nav__link">
            <Link
              className="
          nav__link--anchor
          link__hover-effect
          link__hover-effect--black
        "
              to="/blogs"
            >
              Blogs
            </Link>
          </li>
          <li className="nav__link">
            <Link
              className="
          nav__link--anchor
          link__hover-effect
          link__hover-effect--black
        "
              to="/contact"
            >
              Contact
            </Link>
          </li>
          <li className="nav__link">
            <Link
              className="
          nav__link--anchor
          link__hover-effect
          link__hover-effect--black
        "
              to="/projects"
            >
              Projects
            </Link>
          </li>
          <li className="nav__link">
            <Link
              className="
          nav__link--anchor
          link__hover-effect
          link__hover-effect--black
        "
              to="/games"
            >
              Games
            </Link>
          </li>
          <li className="nav__link click" onClick={() => toggleContrast()}>
            <a
              href="#"
              className="
          nav__link--anchor
          link__hover-effect
          link__hover-effect--black
        "
            >
              <i className="fas fa-adjust"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
