import "./App.css";
import "aos/dist/aos.css";
import AOS from "aos";
import Games from "./pages/Games";
import Snake from "./pages/snakegame/Snake";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Carmania from "./pages/carmania/Carmania";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import MonkeyGame from "./pages/phaser/MonkeyGame";
AOS.init({
  duration: 1000,
});
function App() {
  return (
    <BrowserRouter>
      <>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/games" element={<Games />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/games/snake" element={<Snake />} />
          <Route path="/games/carmania" element={<Carmania />} />
          <Route path="/games/monkey" element={<MonkeyGame />} />
          <Route path="*" element={<LandingPage />} />
        </Routes>
      </>
    </BrowserRouter>
  );
}

export default App;
