import React from "react";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Body from "../components/Body";
import TechStack from "../components/TechStack";
import Footer from "../components/Footer";
import Experience from "../components/Experience";

const LandingPage = () => {
  let isModalOpen = false;
  let contrastToggle = false;

  function toggleContrast() {
    contrastToggle = !contrastToggle;
    if (contrastToggle) {
      document.body.classList += " dark-theme";
    } else {
      document.body.classList.remove("dark-theme");
    }
  }

  function toggleModal() {
    if (isModalOpen) {
      isModalOpen = false;
      return document.body.classList.remove("modal--open");
    }
    isModalOpen = true;
    document.body.classList += " modal--open";
  }
  return (
    <div>
      <Navbar toggleModal={toggleModal} toggleContrast={toggleContrast} />
      <Header toggleModal={toggleModal} toggleContrast={toggleContrast} />
      <Body />
      <Experience />
      <Footer toggleModal={toggleModal} />
    </div>
  );
};

export default LandingPage;
