import React from "react";
import logoImage from "../../../assets/dp. (5).svg";
import "./gamenav.css";
import { Link } from "react-router-dom";
const GameNav = () => {
  return (
    <div>
      <nav className="game__nav">
        <figure>
          <Link to="/">
            <img
              id="personal-logo"
              src={logoImage}
              style={{ borderRadius: "50%" }}
              alt=""
            />
          </Link>
        </figure>
        <div className="game__nav__item">
          <div className=" game__page__title">Dipak Games</div>
        </div>
        <li className="game__nav__item">
          <div className="game__button">
            <span>Play Random</span>
          </div>
        </li>
      </nav>
    </div>
  );
};

export default GameNav;
