export const experiences = [
  {
    title: "Game Developer",
    company_name: "LockPick Games",
    iconBg: "#383E56",
    date: "February 2024 - Today",
    points: [
      "Developing and maintaining RPG games built using RPG maker MZ.",
      "Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.",
      "Implementing responsive design and ensuring cross-browser compatibility.",
      "Participating in code reviews and providing constructive feedback to other developers.",
    ],
  },
  {
    title: "Junior Developer",
    company_name: "TeckCraft Pty Ltd",
    iconBg: "#E6DEDD",
    date: "Jan 2023 - April 2023",
    points: [
      "Developing and maintaining web applications using React.js, PHP and other related technologies.",
      "ETL solution using Talend.",
      "Built solutions using AWS Serverless framework.",
    ],
  },
  {
    title: "Internship",
    company_name: "RebbTech",
    iconBg: "#383E56",
    date: "August 2022 - Jan 2023",
    points: [
      "Code Review and Design Implementation using React, Node js, express and MongoDB.",
    ],
  },
];


