import React, { useEffect, useRef } from "react";
import "./carmania.css";
import useSound from "use-sound";

import driver from "./sprites/driver.webp";
import driverFunny from "./sprites/laughing.png";

import nextSound from "./audio/next.mp3";
import introSound from "./audio/intro.mp3";
import carsound from "./audio/carSound.mp3";
import eatSound from "../snakegame/audio/eat.mp3";
import blastSound from "./audio/crash.mp3";

import grass from "./sprites/grass.png";
import car from "./sprites/12.png";
import coin from "../snakegame/sprites/mushroom.png";
import boom from "./sprites/boom.png";

import kaboom from "kaboom";
const Carmania = () => {
  const canvasRef = useRef(null);
  const [playSound] = useSound(nextSound);
  const [welcomeSound] = useSound(introSound);
  const [eatMushroom] = useSound(eatSound);
  const [startCar, { stop }] = useSound(carsound);
  const [blast] = useSound(blastSound);

  useEffect(() => {
    const k = kaboom({
      global: false,
      canvas: canvasRef.current,
      background: [82, 82, 87],
    });
    k.loadSprite("driver", driver);
    k.loadSprite("driverFunny", driverFunny);
    k.loadSprite("grass", grass);
    k.loadSprite("car", car);
    k.loadSprite("coin", coin);
    k.loadSprite("boom", boom);

    //lets create a scene
    k.scene("intro", ({ levelIdx, score }) => {
      const dialogs = [
        ["driver", "please use the nav-button to navigate."],
        ["driver", "This is desktop game."],
        ["driverFunny", "I recommend using the PC to play this game."],
        ["driver", "Play Safe."],
      ];
      let convo = 0;
      const textbox = k.add([
        k.rect(k.width() - 200, 120, { radius: 32 }),
        k.anchor("center"),
        k.pos(k.center().x, k.height() - 100),
        k.outline(4),
        "descBox",
      ]);
      const txt = k.add([
        k.text("welcome to carMania", {
          size: 24,
          width: k.width() - 230,
          align: "center",
        }),
        k.pos(textbox.pos),
        k.anchor("center"),
        k.color(0, 0, 0),
        "descText",
      ]);
      const spaceButton = k.add([
        k.rect(240, 80, { radius: 8 }),
        k.pos(k.center().x, k.height() - 200),
        k.area(),
        k.scale(1),
        k.anchor("center"),
        k.outline(4),
        "spaceButton",
      ]);
      spaceButton.add([
        k.text("Press Space"),
        k.anchor("right"),
        k.color(1, 1, 1),
        "spaceText",
      ]);
      function updateDialog() {
        const [char, dialog] = dialogs[convo];
        txt.text = dialog;
        //change the sprite
        driver.use(k.sprite(char));
      }
      const driver = k.add([
        k.sprite("driver"),
        k.scale(0.11),
        k.anchor("center"),
        k.pos(textbox.pos.x - 400, textbox.pos.y - 50),
        k.anchor("topleft"),
      ]);

      //do something during the desc time
      k.onKeyPress("space", () => {
        if (convo <= 3) {
          intropart();
        } else {
          welcomeSound();
          k.go("game", { levelIdx, score });
        }
      });
      function intropart() {
        updateDialog();
        convo = convo + 1;
        playSound();
      }
    });
    k.scene("game", ({ levelIdx, score }) => {
      k.setGravity(1000);
      function increaseObsctacle(lvlIdx) {
        if (lvlIdx === 2) {
          return 0.5;
        }
        if (lvlIdx === 3) {
          k.setGravity(900);
          return 0.3;
        }
        if (lvlIdx === 4) {
          return 0.2;
        }
        if (lvlIdx === 5) {
          return 0.1;
        }
      }
      const wallorFood = ["grass", "coin"];
      k.loop(increaseObsctacle(levelIdx) || 0.8, () => {
        let randomnum = k.rand(300, 900);
        let chosenParticle = k.choose(wallorFood);
        let lifeSpan = 2;
        if (chosenParticle === "grass") {
          lifeSpan = 6;
        }
        k.add([
          k.pos(randomnum, 0),
          k.sprite(chosenParticle),
          k.anchor("center"),
          k.scale(1),
          k.area(),
          k.body(),
          k.lifespan(lifeSpan, { fade: 0.3 }),
          k.opacity(1),
          chosenParticle,
        ]);
      });
      k.loop(13, () => {
        startCar();
      });

      function gameZone(score) {
        const LEVELS = [
          [
            "    |                  |",
            "    |                  |",
            "    |                  |",
            "    |                  |",
            "    |                  |",
            "    |   |              |",
            "    |                  |",
            "    |        |         |",
            "    |                  |",
            "    |                  |",
            "    |                  |",
            "    |                  |",
            "    |     |            |",
            "    |                  |",
            "    |      @           |",
            "    |         |        |",
            "    |                  |",
            "    |    |             |",
            "    |                  |",
            "    |                  |",
            "    |                  |",
            "    |                  |",
            "    |                  |",
            "    |                  |",
            "    |                  |",
            "    |   |              |",
            "    |                  |",
            "    |        |         |",
            "    |                  |",
            "    |                  |",
            "    |                  |",
            "    |                  |",
            "    |     |            |",
            "    |                  |",
            "    |                  |",
            "    |         |        |",
            "    |                  |",
            "    |    |             |",
            "    |                  |",
            "    |                  |",
          ],
        ];
        const level = k.addLevel(LEVELS[levelIdx || 0], {
          tileWidth: 40,
          tileHeight: 40,
          tiles: {
            "|": () => [
              k.sprite("grass"),
              k.area(),
              k.body({ isStatic: true }),
              "wall",
            ],
            "-": () => [
              k.sprite("grass"),
              k.color(82, 82, 87),
              k.area(),
              k.body({ isStatic: true }),
              "border",
            ],
            "@": () => [
              k.sprite("car"),
              k.area(),
              k.scale(1.2),
              "player",
            ],
          },
        });
        const player = level.get("player")[0];
        k.onKeyDown("left", () => {
          player.move(-320, 0);
        });
        k.onKeyDown("right", () => {
          player.move(320, 0);
        });
        k.onKeyDown("up", () => {
          player.move(0, -320);
        });
        k.onKeyDown("down", () => {
          player.move(0, 320);
        });
        player.onCollide("wall", (wall) => {
          stop();
          blast();
          player.use(k.sprite("boom"));
          k.shake(4);
          k.go("lose", { levelIdx, score });
        });

        player.onCollideUpdate("coin", (food) => {
          food.destroy();
          eatMushroom();
          addScore();
          increaseLevel();
        });
        player.onCollideUpdate("grass", () => {
          stop();
          blast();
          player.use(k.sprite("boom"));
          k.shake(4);
          k.wait(1, () => {
            k.go("lose", { levelIdx, score });
          });
        });
        const scoreCard = k.add([k.text("score:" + score), k.pos(0, 0)]);
        const levelCard = k.add([
          k.text("Level:" + score),
          k.pos(scoreCard.pos.x, scoreCard.pos.y + 100),
        ]);
        function addScore() {
          score++;
          scoreCard.text = "Score: " + score;
        }
        function increaseLevel() {
          if (score < 5) {
            levelIdx = 1;
            levelCard.text = "Level: " + levelIdx;
          } else if (5 < score < 10) {
            levelIdx = 2;
            levelCard.text = "Level: " + levelIdx;
          }
        }
      }
      gameZone(score);
    });
    k.scene("lose", ({ levelIdx, score }) => {
      k.setBackground();
      k.add([
        k.text("you got only: " + score),
        k.pos(k.center().x - 200, k.center().y + 100),
      ]);
      k.add([
        k.text("you reached level: " + levelIdx),
        k.pos(k.center().x - 200, k.center().y),
      ]);
      const RestartButton = k.add([
        k.rect(240, 49, { radius: 8 }),
        k.pos(k.center().x, k.height() - 200),
        k.area(),
        k.color(34, 139, 34),
        k.anchor("center"),
        k.outline(4),
      ]);
      RestartButton.add([
        k.text("Restart"),
        k.anchor("center"),
        k.color(255, 255, 255),
        "spaceText",
      ]);
      RestartButton.onClick(() => {
        k.go("game", { levelIdx: 0, score: 0 });
        playSound();
      });
    });
      k.go("intro", {
        levelIdx: 0,
        score: 0,
      });
  }, []);
  return (
    <div className="body">
      <div className="game-section ">
        <canvas ref={canvasRef}></canvas>
      </div>
    </div>
  );
};

export default Carmania;
