/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Modal from "./Modal";

const Header = ({toggleModal}) => {
  return (
    <section id="landing-page">
      <header className="header">
        <div className="header__content">
          <h1 className="title">Hey</h1>
          <h1 className="title orange">I'm Dipak.</h1>
          <p className="header__para">
            I'm a <b className="orange">Full-stack Software Engineer</b> with a
            strong passion for building web applications with great user
            experiences.
            <br />
            Here's a bit more{" "}
            <b className="orange click" onClick={() => toggleModal()}>
              about me
            </b>
            .
          </p>
          <div className="social__list">
            <a
            title="visit my linkedIn"
              href="https://www.linkedin.com/in/dipak-paudel/"
              target="_blank"
              className="social__link click"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a
            title="visit my github"
              href="https://github.com/Dipak2056"
              target="_blank"
              className="social__link click"
            >
              <i className="fab fa-github"></i>
            </a>
            <a href="DIPAK-PAUDEL-RESUME.docx" title="download my resume" download target="_blank"  className="social__link click">
              <i className="fas fa-file-pdf"></i>
            </a>
          </div>
        </div>
        <div className="header__content__image">
          <img className="profile__image" alt="profile" loading="lazy" src="	https://s3.ap-southeast-2.amazonaws.com//dummyreactimages/dipakpaudelprofile.png" />
        </div>
      </header>
      <a href="#contact">
        <button className="mail__btn click" onClick={() => toggleModal()}>
          <i className="fas fa-envelope"></i>
        </button>
      </a>
      <a href="#projects" className="scroll">
        <div className="scroll__icon click" title="scroll to project"></div>
      </a>
      <Modal toggleModal={toggleModal} />
    </section>
  );
};

export default Header;