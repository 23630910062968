import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const AppLayout = ({children}) => {
  let contrastToggle = false;

  function toggleContrast() {
    contrastToggle = !contrastToggle;
    if (contrastToggle) {
      document.body.classList += " dark-theme";
    } else {
      document.body.classList.remove("dark-theme");
    }
  }
  return (
    <div>
      <Navbar toggleContrast={toggleContrast} />
      {children}
      <Footer />
    </div>
  );
};

export default AppLayout;
