import React from "react";
import AppLayout from "../components/AppLayout";
import PagesHeader from "../components/PagesHeader";

const Blogs = () => {
  const title = "Hi, Let me know about my";
  const emph = "Blogs";
  const description =
    "I am starting to develop habit of writing blogs. Please Read some of them";

  return (
    <AppLayout>
      <PagesHeader title={title} emph={emph} description={description} />
    </AppLayout>
  );
};

export default Blogs;
