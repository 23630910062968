import React, { useEffect, useRef} from "react";
import useSound from "use-sound";

import mushroom from "./sprites/mushroom.png";
import bean from "./sprites/bean.png";
import grass from "./sprites/grass.png";
import audio from "./audio/eat.mp3";

import kaboom from "kaboom";
import "./snake.css";
const Snake = () => {
  const canvasRef = useRef(null);
  const [playSound] = useSound(audio)
  useEffect(() => {
    const k = kaboom({
      global: false,
      canvas: canvasRef.current,
      background: [82, 82, 87],

    });
    //loading assets
    k.loadSprite("mushroom", mushroom);
    k.loadSprite("bean", bean);
    k.loadSprite("grass", grass);
    //lets create a GAME scene
    k.scene("game", () => {
      //create a platform to play game
      const LEVELS = [
        [
          "                 ",
          " ==============",
          " =            = ",
          " =            = ",
          " =            = ",
          " =            = ",
          " =            = ",
          " =            = ",
          " =            = ",
          " =            = ",
          " =            = ",
          " =            = ",
          " =            = ",
          " =            = ",
          " ==============",
        ],
      ];
      const levelConf = {
        tileWidth: 64,
        tileHeight: 64,
        tiles: {
          "=": () => [
            k.rect(70,70),
            k.color(255,0,0),
            k.area(),
            k.body({ isStatic: true }),
            k.anchor("bot"),
            "wall",
          ],
        },
      };
      //add level to the scene
      const gameRegion = k.addLevel(LEVELS[0], levelConf);
      
      //randomly generate a random mushroom
      let food = null;
      const createFood = () => {
        
        const x = k.rand(120,  k.width() / 2);
        const y = k.rand(80, k.height() / 2);
        if(food){
          k.destroy(food)
        }
        food = k.add([
          k.sprite("mushroom"),
          k.pos(x, y),
          k.area(),
          k.body(),
          k.offscreen({ destroy: true }),
          "food",
        ]);
      };
      const directions = {
        UP: "up",
        DOWN: "down",
        LEFT: "left",
        RIGHT: "right",
      };
      let current_direction = directions.RIGHT;
      let run_action = false;
      let snake_length = 3;
      let snake_body = [];
      let block_size = 20;

      function respawn_snake() {
        k.destroyAll("player");
        snake_body = [];
        snake_length = 3;
        for (let i = 0; i < snake_length; i++) {
          let segment = k.add([
            k.rect(20,20),
            k.color(0,255,0),
            k.pos(120, 120),
            k.area(),
            k.body(),
            k.anchor("center"),
            "snake"
          ]);
          snake_body.push(segment);
        }
        current_direction = directions.RIGHT;
      }
      function respawn_all() {
        run_action = false;
        k.wait(0.5, function () {
          respawn_snake();
          createFood()
          run_action = true;
        });
      }
      respawn_all();
      k.onKeyPress("up", () => {
        if (current_direction !== directions.DOWN) {
          current_direction = directions.UP;
        }
      });

      k.onKeyPress("down", () => {
        if (current_direction !== directions.UP) {
          current_direction = directions.DOWN;
        }
      });

      k.onKeyPress("left", () => {
        if (current_direction !== directions.RIGHT) {
          current_direction = directions.LEFT;
        }
      });

      k.onKeyPress("right", () => {
        if (current_direction !== directions.LEFT) {
          current_direction = directions.RIGHT;
        }
      });

      //move player
      let move_delay = 0.1;
      let timer = 0;
      k.onUpdate(() => {
        if (!run_action) return;
        timer += k.dt();
        if (timer < move_delay) return;
        timer = 0;

        let move_x = 0;
        let move_y = 0;

        switch (current_direction) {
          case directions.DOWN:
            move_x = 0;
            move_y = block_size;
            break;
          case directions.UP:
            move_x = 0;
            move_y = -1 * block_size;
            break;
          case directions.LEFT:
            move_x = -1 * block_size;
            move_y = 0;
            break;
          case directions.RIGHT:
            move_x = block_size;
            move_y = 0;
            break;
          default:
            move_x = 0;
            move_y = 0;
        }

        // Get the last element (the snake head)
        let snake_head = snake_body[snake_body.length - 1];
        let new_head = k.add([
          k.rect(20,20),
          k.color(0,255,0),
          k.pos(snake_head.pos.x + move_x, snake_head.pos.y + move_y),
          k.area(),
          k.body(),
          k.anchor("center"),
          "snake"
        ]);
        snake_body.push(new_head);
        if (snake_body.length > snake_length) {
          let tail = snake_body.shift(); 
          k.destroy(tail);
        }
      });

      //on collision with the food disappear and reappear the food
      let score = 0;
      const scoreCard = k.add([
        k.text("Your score is: "+score),
        k.pos(k.width() / 4, 120),
        k.anchor("center", k.fixed(), k.z(100)),
      ]);
      function addScore() {
        score++;
        scoreCard.text = "Your Score is :"+ score;
      }
      //on collision
      k.onCollide("snake","food", () => {
        snake_length++;
        addScore()
        playSound()
        k.destroy(food)
        createFood()
       });
      //on collision with the food display gameover
      k.onCollide("snake","wall",()=>{
        k.go("lose",{score: score});
      })
      
      k.scene("lose", ({score}) => {
        const RestartButton = k.add([
          k.rect(240, 49, { radius: 8 }),
          k.pos(k.center().x, k.height() - 200),
          k.area(),
          k.color(34, 139, 34),
          k.anchor("center"),
          k.outline(4),
        ]);
        RestartButton.add([
          k.text("Restart:"+ score),
          k.anchor("center"),
          k.color(255, 255, 255),
          "spaceText",
        ]);
        RestartButton.onClick(() => {
          k.go("game", { levelIdx: 0, score: 0 });
          playSound()
        });
      })
      

    });

    // run the game
    k.go("game");
  });
  return (
    <>
      <div className="game-section">
        <canvas ref={canvasRef}></canvas>
      </div>
    </>
  );
};

export default Snake;
