import React from "react";
import { projectsData } from "../projectsdata";

const AllProjects = () => {
  return (
    <section id="projects">
      <div className="container">
        <div className="row">
          <h1 className="section__title" data-aos="flip-left">
            Here are some of my <span className="orange">projects</span>
          </h1>
          <ul className="project__list">
            {projectsData.map((projectData) => (
              <li key={projectData.title} className="project">
                <div className="project__wrapper">
                  <img
                    src={projectData.image}
                    className="project__img"
                    alt=""
                  />
                  <div className="project__wrapper--bg"></div>
                  <div className="project__description">
                    <h3 className="project__description--title">
                      {projectData.title}
                    </h3>
                    <h4 className="project__description--sub-title">
                      {projectData.techs}
                    </h4>
                    <p className="project__description--para">
                      {projectData.description}
                    </p>
                    <div className="project__description--links">
                      <a
                        href={projectData.link}
                        className="project__description--link"
                      >
                        <i className="fas fa-link"></i>
                      </a>
                      {projectData?.secondlink && (
                        <a
                          href={projectData.secondlink}
                          className="project__description--link"
                        >
                          <i className="fas fa-link"></i>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AllProjects;
