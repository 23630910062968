import React from "react";
import "./gameComponents/games.css";
import GameNav from "./gameComponents/nav/GameNav";
import GameHero from "./gameComponents/gamehero/GameHero";
import GameCard from "./gameComponents/gamecard/GameCard";

const Games = () => {
  return (
    <section>
      <GameNav />
      <GameHero />
      <div>
        <h1 style={{ textAlign: "center", fontSize: "3em", margin: "1em" }}>
          Play my <span className="emphasized__text">Games</span>
        </h1>
        <div className="games">
          <GameCard
            gameName="Snake Mania"
            game="snake"
            gameDescription="Play this desktop snake game, use your keyboard control, made with Kaboomjs"
            imageURL="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReaUBEFtRxqCPrXtyuHsxnZrcfUkkyHobOtCCoTj4ppqLCHpgsy5JAMq8JNrfh9gUqOoA&usqp=CAU"
          />
          <GameCard
            gameName="Car Mania"
            game="carmania"
            gameDescription="Play my car control game, suitable for desktop by using cursor, made with Kaboomjs"
            imageURL="https://t4.ftcdn.net/jpg/04/81/79/01/240_F_481790131_p33Lyws3LxRAhAI36biUYoxnH6MqaxAW.jpg"
          />
        </div>
      </div>
    </section>
  );
};

export default Games;
