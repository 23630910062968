/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import logoImage from "../assets/dp. (5).svg";
import { GoogleGenerativeAI } from "@google/generative-ai";

const Footer = ({ toggleModal }) => {
  const [question, setQuestion] = useState("");
  const [genAIrespose, setGenAIrespose] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setQuestion(e.target.value);
  };
  const askAI = async (prompt) => {
    //create model
    const genAI = new GoogleGenerativeAI(process.env.REACT_APP_API_KEY);
    setLoading(true);
    const model = genAI.getGenerativeModel({ model: "gemini-pro" });

    //pass the prompt here
    const result = await model.generateContent(prompt);
    const response = await result.response;
    setGenAIrespose(response.text);
    setLoading(false);
  };
  const handleSubmit = () => {
    askAI(question);
    setQuestion("");
  };

  return (
    <footer>
      <div className="container">
        <div className="row footer__row">
          <a href="/" className="footer__anchor">
            <figure className="footer__logo">
              <img
                src={logoImage}
                style={{ borderRadius: "50%" }}
                className="footer__logo--img"
                alt=""
              />
            </figure>
            <span className="footer__logo--popper">
              Top
              <i className="fas fa-arrow-up"></i>
            </span>
          </a>

          <div className="ask-ai mt-2 flex items-center justify-around align-center w-full ">
            <input
              className="btn btn-primary"
              placeholder="ask me anything..."
              type="text"
              name="ask"
              onChange={handleChange}
              value={question}
            />
            <button className="form__submit" onClick={() => handleSubmit()}>
              ask AI
            </button>
          </div>
          <div className="container mx-auto text-slate-400 py-4">
            {genAIrespose ? genAIrespose : loading && "loading.."}
          </div>
          <div className="footer__social--list">
            <a
              href="https://github.com/Dipak2056"
              className="
        footer__social--link
        link__hover-effect
        link__hover-effect--white
      "
            >
              GitHub
            </a>
            <a
              href="https://www.linkedin.com/in/dipak-paudel/"
              target="_blank"
              className="
        footer__social--link
        link__hover-effect
        link__hover-effect--white
      "
              rel="noreferrer"
            >
              LinkedIn
            </a>
            <a
              href="DIPAK-PAUDEL-RESUME.docx"
              download
              target="_blank"
              className="
        footer__social--link
        link__hover-effect
        link__hover-effect--white
      "
            >
              Resume
            </a>
          </div>
          <div className="footer__copyright">
            Copyright &copy; 2023 Dipak Paudel
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
