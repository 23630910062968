import React from "react";

const PagesHeader = ({ title, emph, description }) => {
  return (
    <div className="contact--header">
      <div className="contact--header--left">
        <h2>
          {title} <span className="emphasized__text">{emph}</span>{" "}
        </h2>
        <p className="contact--header-desc"> {description}</p>
      </div>
      <div className="contact--header--right">
        <div className="header--image">
          <img
            src="https://cdni.iconscout.com/illustration/premium/thumb/contact-us-5795988-4849052.png?f=webp"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default PagesHeader;
