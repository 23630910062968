export const projectsData = [
    {
      image: "./admin-cms.png",
      title: "E-commerce Admin CMS",
      techs: "HTML, CSS, Javascript, React,Node, express, MongoDB",
      description:
        "E-commerce Admin CMS is a fully functional E-commerce CMS. This massive full-stack project is using JWT for authentication, authorization. Multer for image upload. MongoDB for database management and Nodemailer for email services. Well structured API end points and simple UI design. Highly scalable, full stack CMS app.",
      link: "https://github.com/Dipak2056/ecommerce-api",
      secondlink: "https://github.com/Dipak2056/ecommerce-frontend",
    },
    {
      image: "./paudel-grocery.png",
      title: "E-commerce Customers Frontend",
      techs: "HTML, CSS, Javascript, React,Node, express, MongoDB",
      description:
        "E-commerce Admin CMS is a fully functional E-commerce CMS. After we along with our professor completed the CMS, this was our solo challenge to finish the Front side of the e-commerce store. Work is still going on in this app, but there might be few problems rendering the project now because the app is not deployed yet.",
      link: "https://github.com/Dipak2056/frontend-paudelgrocery",
      secondlink: "https://github.com/Dipak2056/paudelgrocery-backend",
    },
    {
      image: "./f4ffrontend.png",
      title: "Commercial, Food4fitness clocking/stocking",
      techs: "HTML, CSS, Javascript, React , Redux, Node, express, MongoDB",
      description:
        "Here is another full-stack App, I am working on currently. To help the company where i was employed. This app helps in timesheet management, clockIn clockOut, and taking record of the stock.",
      link: "https://github.com/Dipak2056/f4ffrontend",
      secondlink: "https://github.com/Dipak2056/food4fitnessbackend",
    },
    {
      image: "./libapp.png",
      title: "Library App frontend",
      techs: "HTML, CSS, Javascript, React",
      description:
        "Here is another frontend App i developed with react. This app is a library app using some local data. This app design is using the concepts of lazy-loading, which make it stand-out of the crowd.",
      link: "https://library-app-ruddy.vercel.app/",
    },
    {
      image: "./memory-app.png",
      title: "Memories-Book",
      techs: " Javascript, React, Material-UI, Redux, Node.js,Mongo-db ",
      description:
        "Memory-book is a full-stack application, where user can create, update and delete their memories with one media-input. The additional feature that comes along with the app is, user can like the post.",
      link: "https://6319a79bf8cf2954c39259e0--dipak-memories.netlify.app/",
    },
    {
      image:"https://s3.ap-southeast-2.amazonaws.com//dummyreactimages/valuefinderlogo.png",
      title:"Value Finder",
      techs:"React, TailwindCSS, AWS Serverless, Node.js, GPT 4",
      description:"AI powered App to analyse your responses and help you discover the things best for you.",
      link:"http://valuefinder.ai.s3-website-ap-southeast-2.amazonaws.com/",
      secondlink:"",
    }
  ];