import React from "react";
import "./gamehero.css";
const GameHero = () => {
  return (
    <div className="game__hero__container">
      <div className="game__hero__heading__text">
        <h2>
          Some <span className="emphasized__text">front-end</span> dipz-games.
        </h2>
        <p className="game__hero__text">
          These games are built using JavaScript and DOM-Manupulation technique,
          hope you'll like them.
        </p>
      </div>
      <div className="games__container">
        <div className="game"> <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReaUBEFtRxqCPrXtyuHsxnZrcfUkkyHobOtCCoTj4ppqLCHpgsy5JAMq8JNrfh9gUqOoA&usqp=CAU" alt="" /></div>
        <div className="game"> <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReaUBEFtRxqCPrXtyuHsxnZrcfUkkyHobOtCCoTj4ppqLCHpgsy5JAMq8JNrfh9gUqOoA&usqp=CAU" alt="" /></div>
        <div className="game"> <img src="https://t4.ftcdn.net/jpg/04/81/79/01/240_F_481790131_p33Lyws3LxRAhAI36biUYoxnH6MqaxAW.jpg" alt="" /></div>
        <div className="game"> <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReaUBEFtRxqCPrXtyuHsxnZrcfUkkyHobOtCCoTj4ppqLCHpgsy5JAMq8JNrfh9gUqOoA&usqp=CAU" alt="" /></div>
      </div>
    </div>
  );
};

export default GameHero;
