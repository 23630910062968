import React from "react";
import "./css/contactpage.css";
import AppLayout from "../components/AppLayout";
import { Box } from "@mui/material";
import PagesHeader from "../components/PagesHeader";

const Contact = () => {
    const title = "Hi,";
    const emph = "Contact Me";
    const description =
      "I am always open for new opportunities. Lets get to know each other.";
  
    
  return (
    <AppLayout>
      <section>
        <PagesHeader title={title} emph={emph} description={description} />
        <div className="contact--card--section">
          <Box
            display="flex"
            alignItems="center"
            marginY={"-10px"}
            textAlign={"center"}
            sx={{
              p: 2,
              width: "400px",
              height: "100px",
              zIndex: 100,
              bgcolor: "#97BC62FF",
              borderRadius: "5px",
              alignItems: "center",
              color: "white",
            }}
          >
            0452450087
          </Box>
          <Box
            display="flex"
            alignItems="center"
            marginY={"-10px"}
            textAlign={"center"}
            sx={{
              p: 2,
              width: "400px",
              height: "100px",
              zIndex: 100,
              bgcolor: "#2C5F2D",
              borderRadius: "5px",
              alignItems: "center",
              color: "white",
            }}
          >
            Paudelsantosh508@gmail.com
          </Box>
        </div>
      </section>
    </AppLayout>
  );
};

export default Contact;
