import "./gamecard.css";
import React from "react";
import { Link } from "react-router-dom";

const GameCard = ({ gameName,game,gameDescription, imageURL }) => {
  return (
    <div className="game__card">
      <div className="game__card__image">
        <img
          src={imageURL}
          alt=""
        />
      </div>
      <div className="game__card__text">
        <span className="game__card__text--header">{gameName}</span>
        <span className="game__card__text--desc">
          {gameDescription}
        </span>
      </div>
      <div className="game__card__playbtn">
        <Link to={`/games/${game}`}>Play</Link>
      </div>
    </div>
  );
};

export default GameCard;
