import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Modal = ({ toggleModal }) => {
  const api = "https://portfoliobackend-roan.vercel.app/api/v1/users";
  const initialState = {
    user: "",
    email: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [emailResponse, setEmailResponse] = useState("sending");
  const [spinner,setSpinner] = useState(false);

  async function sendMessage(url, data) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  let notify = () => toast.info("sending message");
  useEffect(() => {
    emailResponse === "message sent" && toast.success("message sent");
  }, [emailResponse]);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true)
    await sendMessage(api, formData).then((data) => {
      if (data.status === "success") {
        setEmailResponse("message sent");
      }
    });
    setSpinner(false)
    setFormData(initialState);
  };
  const handleOnChange = (e) => {
    let { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <div>
      <div className="modal">
        <div className="modal__half modal__about">
          <h3 className="modal__title modal__title--about">
            Here's a bit about me.
          </h3>
          <h4 className="modal__sub-title modal__sub-title--about">
            Full-stack Software Engineer.
          </h4>
          <p className="modal__para">
            I'm a {" "}
            <b className="orange">Full-Stack Software Engineer</b> with
            experience developing websites using <b className="orange">React</b>{" "}
            <b className="orange">Node</b> and{" "}
            <b className="orange">Mongo db</b>.
            <br />I currently have full time work right in Australia and I am
            flexible working <b className="orange">on-site</b>, and{" "}
            <b className="orange">remote</b>.
          </p>
          <div className="modal__languages">
            <figure className="modal__language">
              <img
                className="modal__language--img"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/HTML5_Badge.svg/240px-HTML5_Badge.svg.png"
                alt=""
              />
              <span className="language__name">HTML</span>
            </figure>
            <figure className="modal__language">
              <img
                className="modal__language--img"
                src={`https://cdn.iconscout.com/icon/free/png-256/css-131-722685.png`}
                alt=""
              />
              <span className="language__name">CSS</span>
            </figure>
            <figure className="modal__language">
              <img
                className="modal__language--img"
                src="https://cdn.iconscout.com/icon/free/png-256/javascript-1-225993.png"
                alt=""
              />
              <span className="language__name">JavaScript</span>
            </figure>
            <figure className="modal__language">
              <img
                className="modal__language--img"
                src="https://cdn.iconscout.com/icon/free/png-256/typescript-3521774-2945272.png"
                alt=""
              />
              <span className="language__name">TypeScript</span>
            </figure>

            <figure className="modal__language">
              <img
                className="modal__language--img"
                src="https://cdn.iconscout.com/icon/free/png-256/react-3-1175109.png"
                alt=""
              />
              <span className="language__name">React</span>
            </figure>
            <figure className="modal__language">
              <img
                className="modal__language--img"
                src="https://img.icons8.com/color/344/nodejs.png"
                alt=""
              />
              <span className="language__name">NodeJS</span>
            </figure>

            <figure className="modal__language">
              <img
                className="modal__language--img"
                src="https://img.icons8.com/color/344/mongodb.png"
                alt=""
              />
              <span className="language__name">Mongo DB</span>
            </figure>
          </div>
        </div>
        <div className="modal__half modal__contact">
          <i
            className="fas fa-times modal__exit click"
            onClick={() => toggleModal()}
          ></i>
          <h3 className="modal__title modal__title--contact">
            Let's have a chat!
          </h3>
          <h3 className="modal__sub-title modal__sub-title--contact">
            I'm currently open to new opportunities.
          </h3>
          <form
            id="contact__form"
            autoComplete="off"
            onSubmit={(e) => onFormSubmit(e)}
          >
            <div className="form__item">
              <label className="form__item--label">Name</label>
              <input
                className="input"
                onChange={handleOnChange}
                name="user"
                type="text"
                value={formData.user_name}
                required
              ></input>
            </div>
            <div className="form__item">
              <label className="form__item--label">Email</label>
              <input
                className="input"
                onChange={handleOnChange}
                name="email"
                type="email"
                value={formData.user_email}
                required
              ></input>
            </div>
            <div className="form__item">
              <label className="form__item--label">Message</label>
              <textarea
                className="input"
                onChange={handleOnChange}
                name="message"
                type="text"
                value={formData.message}
                required
              ></textarea>
            </div>
            <button
              id="contact__submit"
              onClick={notify}
              className="form__submit"
            >
              Send it my way
            </button>
            <ToastContainer />
          </form>
          <div className={spinner ? 'modal__overlay--loading' : 'modal__overlay modal__overlay--loading'}>
            <i className="fas fa-spinner"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
